<template>
  <el-dialog v-model="visible" title="门店管理" width="50%" :before-close="handleClose">
    <hr style="margin-bottom: 20px;" />
    <el-form :model="accountMsg" label-width="120px" :rules="rules" ref="formTop">
      <el-form-item label="门店编号" prop="code">
        <el-input
          style="width: 74%"
          v-model="accountMsg.code"
          placeholder="3~4位数字"
          maxlength="4"
          minlength="3"
          :disabled="accountMsg.id"
        >
          <template #prepend>
            <el-select
              v-model="codeType"
              placeholder="Select"
              :disabled="accountMsg.id"
              style="width: 80px"
              @change="handelCode"
            >
              <el-option label="K" :value="0" />
              <el-option label="T" :value="1" />
            </el-select>
          </template>
        </el-input>
      </el-form-item>
      <el-form-item label="门店名称" prop="name">
        <el-input style="width: 74%" v-model="accountMsg.name" maxlength="30" placeholder="最多30个字" />
      </el-form-item>
      <el-form-item label="门店简称" prop="storeShortName">
        <el-input v-model="accountMsg.storeShortName" style="width: 74%" maxlength="30" placeholder="最多30个字" />
      </el-form-item>
      <el-form-item label="门店区域" prop="regionCode">
        <el-select v-model="accountMsg.regionCode" filterable>
          <el-option
            v-for="(item, index) in storeAreas"
            :key="index"
            :label="item.regionName"
            :value="item.regionCode"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="门店分类">
        <ShopType @changeselectShopType="changeselectShopType" :parentShopTypes="accountMsg.storeStoreType" />
      </el-form-item>
      <el-form-item label="门店收银系统" prop="systemType">
        <el-select v-model="accountMsg.systemType" placeholder="请选择门店收银系统">
          <el-option label="自研系统" :value="1" />
          <el-option label="利通系统" :value="2" />
        </el-select>
      </el-form-item>
      <el-form-item label="绑定组织" prop="organizeCode">
        <el-cascader
          v-model="accountMsg.organizeCode"
          :options="organizational"
          :props="optionsProps"
          :show-all-levels="false"
          filterable
          placeholder="选择组织"
          @change="search"
        />
      </el-form-item>
      <el-form-item label="门店地区" prop="locations">
        <div style="display: flex; justify-content: space-between;">
          <el-select
            v-model="accountMsg.province"
            placeholder="请选择省份"
            filterable
            style="width: 30%;"
            @change="changePro"
          >
            <el-option v-for="item in provinces" :key="item.code" :label="item.name" :value="item.code" />
          </el-select>
          <el-select
            v-model="accountMsg.city"
            placeholder="请选择城市"
            filterable
            style="width: 30%;"
            @change="changeCity"
          >
            <el-option v-for="(item, index) in citys" :key="index" :label="item.name" :value="item.code" />
          </el-select>
          <el-select
            v-model="accountMsg.area"
            placeholder="请选择区县"
            filterable
            style="width: 30%;"
            @change="changeArea"
          >
            <el-option v-for="(item, index) in areas" :key="index" :label="item.name" :value="item.code" />
          </el-select>
        </div>
      </el-form-item>
      <el-form-item label="详细地址" prop="address">
        <el-input
          v-model="accountMsg.address"
          placeholder="请填写详细地址（无需带省市区）"
          @change="changeAddress(null)"
          @blur="changeAddress(null)"
        />
      </el-form-item>
      <el-form-item label="地图信息">
        <div>
          <div style="display: flex;">
            <div style="display: flex;">
              <label style="width: 60px;">经度</label>
              <el-input v-model="accountMsg.locationLongitude" />
            </div>
            <div style="display: flex; margin-left: 20px;">
              <label style="width: 60px;">纬度</label>
              <el-input v-model="accountMsg.locationLatitude" />
            </div>
          </div>
          <div id="container" style="height: 300px; width: 80%;margin-top: 10px;"></div>
        </div>
      </el-form-item>
      <el-form-item label="试运营开业日">
        <el-date-picker
          v-model="accountMsg.testOperateTime"
          type="date"
          placeholder="选择试运营开业日"
          :size="size"
          :disabled-date="disabledDate"
          value-format="YYYY-MM-DD HH:mm:ss"
        />
      </el-form-item>
      <el-form-item label="全证开业日" prop="openTime">
        <el-date-picker
          v-model="accountMsg.openTime"
          type="date"
          placeholder="选择全证开业日"
          :size="size"
          value-format="YYYY-MM-DD HH:mm:ss"
        />
      </el-form-item>
      <el-form-item label="门店电话">
        <el-input style="width: 74%" v-model="accountMsg.storeTelephone" placeholder="请填写门店电话" />
      </el-form-item>
      <el-form-item label="联系人姓名">
        <el-input style="width: 74%" v-model="accountMsg.contractor" maxlength="16" placeholder="最多16个字" />
      </el-form-item>
      <el-form-item label="联系人电话">
        <el-input style="width: 74%" v-model="accountMsg.telephone" placeholder="请填写联系人电话" />
      </el-form-item>
      <el-form-item label="合作状态" prop="state">
        <el-radio-group v-model="accountMsg.state">
          <el-radio :label="1">启用</el-radio>
          <el-radio :label="0">停用</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="滴贯通">
        <el-radio-group v-model="accountMsg.diguantongEnable">
          <el-radio :label="1">启用</el-radio>
          <el-radio :label="0">停用</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="滴贯通门店号" prop="diguantongStoreId" v-if="accountMsg.diguantongEnable === 1">
        <el-input
          style="width: 74%"
          v-model="accountMsg.diguantongStoreId"
          :maxlength="30"
          placeholder="请输入滴贯通门店号"
        />
      </el-form-item>
    </el-form>
    <div style="text-align: center;">
      <el-button type="primary" @click="onSubmit" :disabled="!isEdit" style="width: 100px;">{{
        isEdit ? '确定' : '确定'
      }}</el-button>
      <el-button @click="handleClose" style="width: 100px;">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { jsonp } from 'vue-jsonp'
import _ from 'lodash'
import { ref, reactive, defineComponent, computed, onMounted, nextTick, watch, toRaw } from 'vue'
import API from '../../../service/api'
import { ElMessage } from 'element-plus'
import ShopType from '../../../component/shop-type.vue'
export default defineComponent({
  name: 'AddShop',
  props: {
    dialogVisible: Boolean,
    isEdit: Boolean,
    accountMsg: Object,
  },
  components: {
    ShopType,
  },
  setup(props, ctx) {
    const formTop = ref(null)
    const data = reactive({
      provinces: [],
      citys: [],
      areas: [],
      provinceName: '',
      cityName: '',
      areaName: '',
      organizational: [],
      optionsProps: {
        label: 'name',
        children: 'children',
        value: 'code',
      },
      shopTyps: [],
    })
    const codeType = ref(0)
    const handelCode = val => {
      codeType.value = val
    }
    const visible = computed(() => {
      return props.dialogVisible
    })

    //日期选择器设置
    const disabledDate = time => {
      return time.getTime() < Date.now()
    }
    //日期选择器设置
    const disabledopenTimeData = time => {
      return time.getTime() < Date.now()
    }

    const storeAreas = ref([])
    /**
     * 获取门店区域系你行
     */
    const getStoreArea = async () => {
      let res = await API.getRegionList()
      if (res.code === '200') {
        storeAreas.value = res.data.records
      } else {
        ElMessage.error(res.msg)
      }
    }
    onMounted(() => {
      const emnu = { K: 0, T: 1 }
      if (props.accountMsg.fullCode) {
        codeType.value = emnu[props.accountMsg.fullCode.slice(0, 1)]
      }
      API.getAreaWithChildList(null).then(res => {
        if (res.code === '200') {
          data.provinces.push(...res.data)
          initCityAreaData()
        } else {
          ElMessage.error(res.msg)
        }
      })
      //获取门店区域
      const getTreeData = data => {
        for (var i = 0; i < data.length; i++) {
          if (data[i].children.length < 1) {
            // children若为空数组，则将children设为undefined
            data[i].children = undefined
          } else {
            // children若不为空数组，则继续 递归调用 本方法
            getTreeData(data[i].children)
          }
        }
        return data
      }
      API.bindNewOrganizational().then(res => {
        if (res.code === '200') {
          data.organizational.push(...getTreeData(res.data))
        } else {
          ElMessage.error(res.msg)
        }
      })
      storeTypeSelectInfoPage()
      getStoreArea()
    })

    //初始化省市区数据
    const initCityAreaData = () => {
      data.citys.length = 0
      data.areas.length = 0
      data.citys.push(...toRaw(data.provinces.find(item => item.code === props.accountMsg.province)?.children || []))
      data.areas.push(...toRaw(data.citys.find(item => item.code === props.accountMsg.city)?.children || []))
    }

    watch(
      () => props.dialogVisible,
      value => {
        if (value) {
          if (Reflect.has(props.accountMsg, 'city')) {
            initCityAreaData()
            nextTick(() => {
              initMap()
              changeAddress({ lng: props.accountMsg.locationLongitude, lat: props.accountMsg.locationLatitude })
            })
          } else {
            nextTick(() => {
              initMap()
              changeAddress(null)
            })
          }
        }
      },
      { immediate: true },
    )

    // const setPositionByJWD = () => {
    //   if (Reflect.has(props.accountMsg, 'locationLongitude') && Reflect.has(props.accountMsg, 'locationLatitude')) {
    //     map.centerAndZoom(new BMapGL.Point(props.accountMsg.locationLongitude, props.accountMsg.locationLatitude), 15)
    //     local.searchInBounds([props.accountMsg.address], map.getBounds())
    //   } else {
    //     map.centerAndZoom('北京', 15)
    //   }
    // }
    // 清空经纬度
    const clearLocation = () => {
      props.accountMsg.locationLongitude = 0
      props.accountMsg.locationLatitude = 0
    }

    // 设置经纬度
    const setLocation = (locationLongitude, locationLatitude) => {
      props.accountMsg.locationLongitude = locationLongitude
      props.accountMsg.locationLatitude = locationLatitude
    }

    const changePro = val => {
      data.citys.length = 0
      data.areas.length = 0
      props.accountMsg.provinceName = data.provinces.find(item => item.code === val).name
      data.citys.push(...toRaw(data.provinces.find(item => item.code === val)?.children || []))

      props.accountMsg.address = undefined
      props.accountMsg.city = undefined
      props.accountMsg.area = undefined

      // clearLocation()
      // getLal(data.provinces.find(item => item.code === val).name)
    }
    const changeCity = val => {
      data.areas.length = 0
      data.areas.push(...toRaw(data.citys.find(item => item.code === val)?.children || []))
      props.accountMsg.cityName = data.citys.find(item => item.code === val).name

      props.accountMsg.address = undefined
      props.accountMsg.area = undefined
    }
    const changeArea = val => {
      let areaName = data.areas.find(item => item.code === val)?.name || '北京'
      props.accountMsg.areaName = data.areas.find(item => item.code === val).name
      props.accountMsg.areaName = areaName
      props.accountMsg.address = undefined
    }

    // 初始化地图及设置标记
    const initMap = (lat = 39.90923, lng = 116.397428) => {
      var myLatlng = new qq.maps.LatLng(lat, lng)
      var myOptions = {
        zoom: 16,
        center: myLatlng,
        mapTypeId: qq.maps.MapTypeId.ROADMAP,
      }
      map = new qq.maps.Map(document.getElementById('container'), myOptions)
      var marker = new qq.maps.Marker({
        // 设置标记
        position: myLatlng,
        draggable: true,
        animation: qq.maps.MarkerAnimation.DROP,
        map: map,
      })
      qq.maps.event.addListener(marker, 'dragend', function(e) {
        // 监听标记拖动
        var latLng = marker.getPosition()
        map.setCenter(latLng)
        setLocation(latLng.lng, latLng.lat)
      })
    }

    var map = null
    //修改地址
    const changeAddress = location => {
      if (!props.accountMsg.address) {
        clearLocation()
      } else {
        if (location) {
          let center = new qq.maps.LatLng(location.lat, location.lng)
          map.panTo(center)
          initMap(location.lat, location.lng)
          return
        }
        // codeAddress(`${toRaw(data.provinceName)},${toRaw(data.cityName)},${toRaw(data.areaName)},${address}`)
        getLocation(
          `${toRaw(props.accountMsg?.provinceName)},${props.accountMsg?.cityName},${props.accountMsg?.areaName},${
            props.accountMsg?.address
          }`,
        )
      }
    }
    //获取经纬度
    const getLocation = address => {
      jsonp('https://apis.map.qq.com/ws/geocoder/v1/?address=', {
        output: 'jsonp',
        address,
        key: '26TBZ-YNNK3-WIV32-OVCAJ-YYQS5-BMFCY',
      })
        .then(res => {
          if (res.status == 0) {
            // 通过地址得到经纬度
            setLocation(res.result.location.lng, res.result.location.lat)
            let center = new qq.maps.LatLng(res.result.location.lat, res.result.location.lng)
            map.panTo(center)
            initMap(res.result.location.lat, res.result.location.lng)
          } else {
            proxy.$util.messages(res.message)
          }
        })
        .catch(err => {
          console.log('地图错误：', err)
        })
    }

    const codeAddress = address => {
      //通过getLocation();方法获取位置信息值
      geocoder.getLocation(address)
    }

    const checkLocation = (rule, value, callback) => {
      if (!props.accountMsg.province || !props.accountMsg.city || !props.accountMsg.area) {
        return callback(new Error('请填写省市区'))
      } else {
        callback()
      }
    }

    const checkCode = (rule, value, callback) => {
      if (!props.accountMsg.code) {
        return callback(new Error('请填写门店编号'))
      } else if (props.accountMsg.code.length < 3 || props.accountMsg.code.length > 4) {
        return callback(new Error('门店编号长度为3-4位'))
      } else if (!/\d{3,4}/.test(props.accountMsg.code)) {
        return callback(new Error('门店编号为数字'))
      } else {
        callback()
      }
    }

    const checkStoreStoreType = (rule, value, callback) => {
      let myStoreTypes = props.accountMsg.storeStoreType || []
      if (data.shopTyps.length > myStoreTypes.length) {
        return callback(new Error('请完成所有门店类型的选择'))
      } else {
        callback()
      }
    }

    const checkOpenTime = (rule, value, callback) => {
      if (!props.accountMsg.testOperateTime) {
        callback()
      }
      if (
        props.accountMsg.openTime &&
        new Date(props.accountMsg.openTime) < new Date(props.accountMsg.testOperateTime)
      ) {
        return callback(new Error('全证开业日时间必须在试运营开业日之后'))
      } else {
        callback()
      }
    }

    const rules = reactive({
      code: [{ required: true, trigger: 'blur', validator: checkCode }],
      state: [{ required: true, trigger: 'blur', message: '请选择合作状态' }],
      name: [
        { required: true, message: '请填写门店名称', trigger: 'change' },
        { max: 32, message: '最多32个字', trigger: 'change' },
      ],
      storeShortName: [
        { required: true, message: '请填写门店简称', trigger: 'change' },
        { max: 32, message: '最多32个字', trigger: 'change' },
      ],
      storeStoreType: [
        {
          required: true,
          trigger: 'change',
          validator: checkStoreStoreType,
        },
      ],
      // openTime: [
      //   {
      //     required: false,
      //     trigger: 'change',
      //     validator: checkOpenTime,
      //   },
      // ],
      characterType: [{ required: true, message: '请选择门店性质类型', trigger: 'change' }],
      sizeType: [{ required: true, message: '请选择门店大小类型', trigger: 'change' }],
      rankType: [{ required: true, message: '请选择门店等级类型', trigger: 'change' }],
      telephone: [{ required: true, trigger: 'change', message: '请填写联系人电话' }],
      storeTelephone: [{ required: true, trigger: 'change', message: '请填写门店电话' }],
      locations: [{ required: true, trigger: 'change', validator: checkLocation }],
      organizeCode: [{ required: true, message: '请选择组织', trigger: 'change' }],
      address: [{ required: true, message: '请填写详细地址', trigger: 'change' }],
      regionCode: [{ required: true, message: '请选择门店区域', trigger: 'change' }],
      systemType: [{ required: true, message: '请选择门店收银系统', trigger: 'change' }],
      diguantongStoreId: [{ required: true, message: '请输入滴贯通门店号', trigger: 'change' }],
      contractor: [
        { required: true, message: '请填写联系人姓名', trigger: 'change' },
        { max: 16, message: '最多16个字', trigger: 'change' },
      ],
    })

    const storeTypeSelectInfoPage = async () => {
      let res = await API.storeTypeSelectInfoPage()
      if (res.code === '200') {
        data.shopTyps = res.data
      } else {
        ElMessage.error(res.msg)
      }
    }

    const changeselectShopType = data => {
      let dataCopy = Object.assign({ storeCode: 'K' + props.accountMsg.code }, data)
      delete dataCopy.code
      let isExitIndex = props.accountMsg.storeStoreType?.findIndex(item => item.categoryCode === data.categoryCode)
      if (isExitIndex === -1) {
        props.accountMsg.storeStoreType.push(dataCopy)
      } else {
        props.accountMsg.storeStoreType.splice(isExitIndex, 1, dataCopy)
      }
    }
    const onSubmit = _.throttle(() => {
      validFormTop(formTop)
    }, 2000)

    //校验上半部分
    const validFormTop = async formTop => {
      if (!formTop) return
      await formTop.value.validate((valid, fields) => {
        if (valid) {
          sendData()
        } else {
          ElMessage.info('请填写完成必要信息')
        }
      })
    }

    const sendData = () => {
      const emnu = { 0: 'K', 1: 'T' }
      let params = {
        ...toRaw(props.accountMsg),
        location: `${props.accountMsg.locationLongitude},${props.accountMsg.locationLatitude}`,
        code: emnu[codeType.value] + props.accountMsg.code,
      }
      if (Array.isArray(props.accountMsg.organizeCode)) {
        params.organizeCode = props.accountMsg.organizeCode[props.accountMsg.organizeCode.length - 1]
      }

      if (Reflect.has(props.accountMsg, 'id')) {
        update(params)
      } else {
        add(params)
      }
    }

    const add = data => {
      API.addStoreStore(data).then(res => {
        if (res.code === '200') {
          ElMessage.success('添加成功')
          ctx.emit('update-dialogVisible', { update: false, search: true })
          resetForm()
        } else {
          ElMessage.error(res.msg)
        }
      })
    }

    const update = data => {
      API.modifyStoreStore(data).then(res => {
        if (res.code === '200') {
          ElMessage.success('编辑成功')
          ctx.emit('update-dialogVisible', { update: false, search: true })
          resetForm()
        } else {
          ElMessage.error(res.msg)
        }
      })
    }

    const resetForm = () => {
      formTop.value.resetFields()
    }

    const handleClose = () => {
      resetForm()
      ctx.emit('update-dialogVisible', { update: false })
    }
    const addRow = () => {
      props.accountMsg.list.push({ accountType: '1' })
    }
    const deleteRow = index => {
      if (props.accountMsg.list.length === 1) {
        return
      } else {
        props.accountMsg.list.splice(index, 1)
      }
    }
    return {
      onSubmit,
      handleClose,
      changeAddress,
      changePro,
      changeArea,
      changeCity,
      formTop,
      props: { multiple: true },
      visible,
      addRow,
      deleteRow,
      rules,
      ...data,
      changeselectShopType,
      disabledDate,
      disabledopenTimeData,
      storeAreas,
      codeType,
      handelCode,
    }
  },
})
</script>
<style lang="scss" scoped>
.el-form-item {
  ::v-deep .el-form-item__label {
    width: 120px !important;
  }
}
.el-row {
  margin-bottom: 20px;
  width: 96%;
  margin-left: 7% !important;
}
.endMsgTitle {
  text-align: center;
  span {
    color: black;
    font-weight: 500;
    font-size: 12px;
  }
}
</style>
